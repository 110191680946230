export const sponsorArrGold = [
  {
    logo: "/images/sponsors/barclays-01.png",
    sponsorship: "gold",
    link: "https://search.jobs.barclays/barclays-developers-prague",
  },
];

export const sponsorArrSilver = [
  {
    logo: "/images/sponsors/barclays-01.png",
    sponsorship: "gold",
    link: "https://search.jobs.barclays/barclays-developers-prague",
  },
];

export const sponsorArrCommunity = [
  {
    logo: "/images/sponsors/techaboo.png",
    sponsorship: "community",
    link: "https://techtaboo.dev/",
  },
  {
    logo: "/images/sponsors/ng.png",
    sponsorship: "community",
    link: "https://www.ngparty.cz",
  },
  {
    logo: "/images/sponsors/oakslab.png",
    sponsorship: "community",
    link: "https://www.oakslab.com/",
  },
  {
    logo: "/images/sponsors/puntoverde.png",
    sponsorship: "community",
    link: "https://www.puntoverde.cz/",
  },
  {
    logo: "/images/sponsors/satoshilabs.png",
    sponsorship: "community",
    link: "https://satoshilabs.com",
  },
  {
    logo: "/images/sponsors/logo_applifting-300x135.png",
    sponsorship: "community",
    link: "https://www.applifting.cz/",
  },
  {
    logo: "images/sponsors/livesport.png",
    sponsorship: "community",
    link: "https://www.livesport.eu/",
  },
];
