import React from "react";
import {
  SponsorContainer,
  SponsorImgContainer,
  GoldSponsorship,
  SilverSponsorship,
  CommunitySponsorship,
  MewsLogo,
} from "./Sponsor.elements";
import { SponsorImg, DeepnoteLogo } from "./Sponsor.elements";
import { Container, TitleCenter } from "../reusable/styled";
import { sponsorArrSilver } from "./sponsorArr";

function Sponsor() {
  return (
    <Container>
      <SponsorContainer>
        <TitleCenter>Naši partneři</TitleCenter>
        <GoldSponsorship>
          GOLD
          <SponsorImgContainer>
            <a href="https://www.mews.com/en" target="blank">
              <MewsLogo src="/images/sponsors/MEWS.png" />
            </a>
          </SponsorImgContainer>
        </GoldSponsorship>
        <SilverSponsorship>
          SILVER
          <SponsorImgContainer>
            {sponsorArrSilver.map((sponsor) => {
              return (
                <a href={sponsor.link} target="blank">
                  <SponsorImg src={sponsor.logo} key={sponsor.link} />
                </a>
              );
            })}
          </SponsorImgContainer>
        </SilverSponsorship>
        <CommunitySponsorship>
          COMMUNITY AND MEETUPS
          <SponsorImgContainer>
            <a href="https://techtaboo.dev/" target="blank">
              <SponsorImg src="/images/sponsors/techaboo.png" />
            </a>
            <a href="https://www.ngparty.cz/" target="blank">
              <SponsorImg src="/images/sponsors/ng.png" />
            </a>

            <a href="https://www.puntoverde.cz/" target="blank">
              <SponsorImg src="/images/sponsors/puntoverde.png" />
            </a>
            <a href="https://edupunk.cz/" target="blank">
              <SponsorImg src="/images/sponsors/edupunk-150x150.jpg" />
            </a>
            <a href="https://fel.cvut.cz/" target="blank">
              <SponsorImg src="/images/sponsors/logo_FEL.svg" />
            </a>
            <a href="https://fokusindustry.cz/" target="blank">
              <SponsorImg src="/images/sponsors/fokus-logo.jpg" />
            </a>
            <a href="https://satoshilabs.com" target="blank">
              <SponsorImg src="/images/sponsors/satoshilabs.png" />
            </a>

            <a href="https://www.productboard.com/" target="blank">
              <SponsorImg src="/images/sponsors/productboard.png" />
            </a>
            <a href="https://www.applifting.cz/" target="blank">
              <SponsorImg src="/images/sponsors/logo_applifting-300x135.png" />
            </a>
            <a href="https://www.credoventures.com/" target="blank">
              <SponsorImg src="/images/sponsors/credo.png" />
            </a>
            <a href="https://deepnote.com/" target="blank">
              <DeepnoteLogo
                height="35px"
                src="/images/sponsors/deepnote2.png"
              />
            </a>
            <a href="https://www.livesport.eu/" target="blank">
              <SponsorImg src="images/sponsors/livesport.png" />
            </a>
          </SponsorImgContainer>
        </CommunitySponsorship>
      </SponsorContainer>
    </Container>
  );
}

export default Sponsor;
