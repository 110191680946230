import React from "react";
import { SlackButton } from "./Slack.elements";

function Slack() {
  const handleAddToSlack = () => {
    window.open(
      "https://join.slack.com/t/reactgirls/shared_invite/zt-13s227qut-vopmSvIsVjbIt_GNTHPetA",
      "_blank"
    );
  };

  return <SlackButton onClick={handleAddToSlack}>Slack</SlackButton>;
}

export default Slack;
