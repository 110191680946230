import React from 'react';
import {
    ContactPeople, ContactBank, ContactFormLectors
}  from '../../components';

function Contact() {
    return (
        <>
            <ContactFormLectors />
            <ContactPeople />
            <ContactBank />
        </>
    )
}

export default Contact;
