import React from "react";
import {
  NewsletterContainer,
  ParagraphCenter,
  TitleCenterNews,
} from "./Newsletter.elements";
import Slack from "../Slack/Slack";
import { Container, Wrapper } from "../styled";

// We changed Newsletter for Slack, this component has a bad naming

function NewsletterSection() {
  return (
    <Container id="newsletterHero">
      <Wrapper>
        <NewsletterContainer>
          <TitleCenterNews>
            Zajímá tě, kdy se bude konat další akce?
          </TitleCenterNews>
          <ParagraphCenter>Připoj se do našeho Slacku!</ParagraphCenter>
          <Slack />
        </NewsletterContainer>
      </Wrapper>
    </Container>
  );
}

export default NewsletterSection;
