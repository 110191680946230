import React from "react";
import { Container, Wrapper } from "../styled";

import {
  PriceContainer,
  PriceRow,
  PriceIcon,
  PriceText,
  PriceMentArea,
  PriceRectangle,
  PriceWrapper,
  TitlePrice,
  RegisterWrapper,
} from "./Price.elements";
import { RegisterButton } from "../Hero/RegisterButton";

function PriceMentoring({
  mainPriceTitle,
  data,
  mentPrice,
  dataJunior,
  form,
  showLogInButton,
  showBorderBottom,
}) {
  return (
    <Container id="price">
      <Wrapper>
        <PriceWrapper showBorderBottom={showBorderBottom}>
          <TitlePrice>{mainPriceTitle}</TitlePrice>
          <PriceRectangle>
            <PriceMentArea>{mentPrice?.price}</PriceMentArea>
            <PriceContainer>
              {data?.map((item) => {
                return (
                  <PriceRow key={item.text} data={dataJunior}>
                    <PriceIcon src="/images/icons/done.svg" />
                    <PriceText>{item.text}</PriceText>
                  </PriceRow>
                );
              })}

              <RegisterWrapper>
                <RegisterButton showLogInButton={showLogInButton} form={form} />
              </RegisterWrapper>
            </PriceContainer>
          </PriceRectangle>
        </PriceWrapper>
      </Wrapper>
    </Container>
  );
}

export default PriceMentoring;
