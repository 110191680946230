import React from 'react';
import { Container, Wrapper } from '../reusable/styled';
import { useTheme } from '../../ThemeContext';
import { 
    ContactHeroTitle,
    ContactInfo,
    ContactHeroContainer,
} from './ContactHero.elements';
import { LinkA } from "../../components/reusable/styled";


function ContactFormLectors() {
    const navLogo = useTheme();
    return (
        <Container lightBlue>
            <Wrapper>
                <ContactHeroContainer className={navLogo ? 'active' : ''}>
                    <ContactHeroTitle>Chceš se zapojit jako lektor?</ContactHeroTitle>
                    <ContactInfo>
                    Bližší informace a možnost zapojení se do našich kurzů nalezneš ve <LinkA href="https://forms.gle/BgoQNAUkqK2bv8ay7" target="blank">formuláři</LinkA>.
                    </ContactInfo>
                    
                </ContactHeroContainer>
            </Wrapper>
        </Container>
    )
}

export default ContactFormLectors;
