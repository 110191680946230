import { LinkA, LinkScroll } from "../../components/reusable/styled";

/* switch between Newsletter and LogInButton on Hero Academy page*/

export const heroObjThree = {
  title: "Zúčastni se intenzivní Akademie s ReactGirls!",
  buttonText: "ODEBÍRAT NEWSLETTER",
  img: "/images/gallery/academy.jpg",
  formJunior:
    "https://docs.google.com/forms/d/e/1FAIpQLSeN6pII2E1Hrb3x8vDOCmYRqOfGqK4vail1KQEi6iI2Zypttw/viewform",
  formSenior:
    "https://docs.google.com/forms/d/e/1FAIpQLSeN6pII2E1Hrb3x8vDOCmYRqOfGqK4vail1KQEi6iI2Zypttw/viewform",
  showNewsletter: false,
  showLogInButton: false,
  isDisabled: false,
};

/* switch between Newsletter and LogInButton on Price Container of Academy page*/

export const acadPrice = {
  price: "",
  form: "https://docs.google.com/forms/d/e/1FAIpQLSeN6pII2E1Hrb3x8vDOCmYRqOfGqK4vail1KQEi6iI2Zypttw/viewform",
  showNewsletter: false,
  showLogInButton: true,
  showBorderBottom: true,
  isDisabled: false,
};

export const infoObjTwo = {
  text: "Další běh akademií HTML, CSS, JS, React, TS plánujeme na jaro 2025.",
  content: "academy",
};

export const academyCardArr = [
  {
    icon: "/images/icons/programming.svg",
    title: "Programování",
    text: "Seznámení se s programováním v jazyce JavaScript - vhodné pro začátečníky, nebo rovnou Reactem - vhodné pro pokročilejší.",
  },
  {
    icon: "/images/icons/profesional.svg",
    title: "Profesionály",
    text: "Vybrali jsme pro vás ty nejlepší lektory, kteří jsou profesionálové v IT oboru a mají bohaté zkušenosti.",
  },
  {
    icon: "/images/icons/presentation.svg",
    title: "Lekce",
    text: "Zúčastníte se lekcí zaměřených na budování vašich technických dovedností v HTML, CSS, JavaScript nebo React.",
  },
  {
    icon: "/images/icons/marathon.svg",
    title: "Workshop",
    text: "Budete součástí závěrečného workshopu, kde si získané znalosti ověříte v praxi. Získáte od nás i certifikát o absolvování akademie.",
  },
  {
    icon: "/images/icons/interview.svg",
    title: "Mock interview",
    text: "Po absolvování akademie budete mít možnost vyzkoušet si mock interview se zkušeným HR.",
  },
  {
    icon: "/images/icons/call.svg",
    title: "Online lekce",
    text: "V případě nepříznivé situace přesuneme všechny lekce do online prostředí a vy tak o nic nepřijdete!",
  },
];

export const titleObjTwo = {
  mainStudentTitle: "Komu je Akademie určena?",
  mainCardsTitle: "Co Akademie nabízí?",
  mainPriceTitle: "Co vše je v ceně Akademie?",
};

export const acadStudentArr = [
  {
    icon: "/images/icons/website.svg",
    heading: "Zájemkyním",
    text: "Zájemkyním o programováním, které se chtějí naučit tvorbu webových stránek a vytvořit si svou vlastní aplikaci v JavaScript nebo Reactu.",
  },
  {
    icon: "/images/icons/victory.svg",
    heading: "Začátečnicím",
    text: "Začátečnicím, které mají základní nebo žádné zkušenosti s programováním a chtěly by se seznámit se s HTML, CSS, JavaScriptem nebo Reactem.",
  },
  {
    icon: "/images/icons/woman3.svg",
    heading: "Ženám",
    text: "Ženám, které se chtějí naučit frontendové programování a vyzkoušet si získané znalosti v praxi. Zároveň si vyzkouší případné mock interview.",
  },
];

export const acadPriceArrJunior = [
  {
    text: "5 offline lekcí programování a workshop",
  },
  {
    text: "online studijní materiály k akademii",
  },
  {
    text: "certifikát o absolvování",
  },
  {
    text: "možnost vyzkoušet si mock interview s HR",
  },
];

export const acadPriceArrSenior = [
  {
    text: "offline lekce programování",
  },
  {
    text: "studijní materiály k akademii na githubu",
  },
  {
    text: "certifikát o absolvování",
  },
];

export const programArr1Junior = [
  {
    date: "28. května 2024",
    topic: "TypeScript",
    time: "18:00 - 21:00",
    place: "Accolade",
    border: true,
  },
  {
    date: "30. května 2024",
    topic: "TypeScript",
    time: "18:00 - 21:00",
    place: "Accolade",
    border: true,
  },
  {
    date: "1. června 2024",
    topic: "TypeScript",
    time: "9:00 - 17:00",
    place: "Accolade",
    border: true,
  },
];

export const programArr1Senior = [
  {
    date: "11. března 2024",
    topic: "JavaScript",
    time: "18:00 - 21:00",
    place: "FEL ČVUT",
    border: true,
  },
  {
    date: "13. března 2024",
    topic: "JavaScript",
    time: "18:00 - 21:00",
    place: "FEL ČVUT",
    border: true,
  },
  {
    date: "18. března 2024",
    topic: "JavaScript",
    time: "18:00 - 21:00",
    place: "FEL ČVUT",
    border: true,
  },
  {
    date: "20. března 2024",
    topic: "JavaScript",
    time: "18:00 - 21:00",
    place: "FEL ČVUT",
    border: false,
  },
  {
    date: "23. března 2024",
    topic: "JavaScript",
    time: "9:00 - 17:00",
    place: "FEL ČVUT",
    border: false,
  },
];

export const acadQuestionsArr = [
  {
    question: "Pro koho je akademie určena?",
    answer:
      "Akademie je určena pro všechny ženy, které se chtějí rekvalifikovat do IT. Není potřeba mít žádné předchozí zkušenosti s programováním. Vše vás naučíme.",
  },
  {
    question: "Jak se můžu na akademii přihlásit? ",
    answer: (
      <>
        Jakmile bude otevřeno přihlašování na akademii, tak zde bude link na
        registrační formulář, který stačí jen vyplnit. Obratem ti na e-mail
        pošleme všechny nezbytné informace a informace o platbě.
      </>
    ),
  },
  {
    question: "Jak bude Akademie probíhat? ",
    answer: (
      <>
        Akademie bude probíhat prezenčně, nebo online. Materiály budou k
        dispozici na Slacku, Notionu a Google Disku. Pokud je Akademie aktivní,
        v harmonogramu{" "}
        <LinkScroll
          to="program"
          smooth={true}
          duration="700"
          spy={true}
          exact="true"
          offset={-80}
        >
          výše
        </LinkScroll>{" "}
        jsou vypsány časy jednotlivých lekcí.
      </>
    ),
  },
  {
    question: "Co si z Akademie odnesu? ",
    answer:
      "Odneseš si znalosti HTML, CSS, JavaScriptu nebo Reactu. Získáš reálnou zkušenost s kódem a motivaci do dalšího seberozvoje. Budeš mít možnost si vyzkoušet interview se zkušeným HR a dostaneš certifikát o absolvování Akademie a ReactGirls merch. Odneseš si i skvělé strávený čas se super partou!",
  },
  {
    question: "Kde se bude Akademie konat? ",
    answer:
      "Všechny bližší informace ti pošleme e-mailem, akademie se bude konat v kancelářích jednoho z našich partnerů v Praze.",
  },
  {
    question: "Stále si nejsi jistá?",
    answer: (
      <>
        Pokud se chceš ujistit, že je Akademie pro tebe to pravé, případně máš
        ještě další otázky, neváhej nás{" "}
        <LinkA href="/kontakt">kontaktovat</LinkA>.
      </>
    ),
  },
];

export const processArrAcad = [
  {
    number: 1,
    heading: "REGISTRACE",
    text: "V případě, že je momentálně otevřené přihlašování na Akademii, klikni níže na tlačítko REGISTRACE. Pokud aktuálně není registrace otevřena, můžeš odebírat náš Newsletter, kde se o startu dalšího běhu Akademie v předstihu dozvíš.",
    reversed: false,
    last: false,
    first: true,
  },
  {
    number: 2,
    heading: "VYPLŇ FORMULÁŘ",
    text: "Po kliknutí na tlačítko REGISTRACE se objeví formulář. Při vyplňování nezapomeň zejména na popsání své motivace. Kapacita Akademie je omezená, proto s přihlašováním neváhej!",
    reversed: true,
    last: false,
    first: false,
  },
  {
    number: 3,
    heading: "KONTAKTUJEME TĚ",
    text: "Po přihlášení tě kontaktujeme s bližšími informacemi ohledně akademie a platby.",
    reversed: false,
    last: false,
    first: false,
  },
  {
    number: 4,
    heading: "ZAČÍNÁME",
    text: "Konkrétní informace k přípravě na jednotlivé hodiny zasíláme pár dní před každou lekcí.",
    reversed: true,
    last: true,
    first: false,
  },
];
