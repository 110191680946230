import {
  ButtonHero,
  ButtonHeroDisabled,
  StyledLink,
  Wrapper,
} from "./Hero.elements";

import React from "react";

export const RegisterButton = ({
  showLogInButton,
  formJunior,
  form,
  isDisabled,
}) => (
  <>
    {showLogInButton && (
      <Wrapper>
        {formJunior && !isDisabled && (
          <StyledLink href={formJunior} target="_blank">
            <ButtonHero>Registrace</ButtonHero>
          </StyledLink>
        )}
        {isDisabled && (
          <ButtonHeroDisabled>Registrace ukončena</ButtonHeroDisabled>
        )}
        {form && !isDisabled && (
          <StyledLink href={form} target="_blank">
            <ButtonHero>Registrace</ButtonHero>
          </StyledLink>
        )}
      </Wrapper>
    )}
  </>
);
