import React from "react";
import styled from "styled-components";
import { breakpoints } from "../variables";

export const InfoButton = styled.button`
  background-color: #00b4cd;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  margin-top: 16px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #008c9e;
    transform: translateY(-2px);
  }
`;

const Wrapper = styled.div`
  max-width: 400px;
  width: 1000px;
  border: 2px solid #00b4cd;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  margin: 16px;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 32px;
`;

export const Banner = () => {
  return (
    <BannerWrapper>
      <Wrapper>
        <h2>Přihlašování na mentoring spuštěno!</h2>
        <p>
          Více informací v <a href="/mentoring">Mentoring</a>
        </p>
        <InfoButton
          onClick={() =>
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSePV5-a5t0hUe-RQJw4W9w0gbi-UZDknOK_h91gvPVw3tADEA/viewform",
              "_blank"
            )
          }
        >
          Registrace
        </InfoButton>
      </Wrapper>
    </BannerWrapper>
  );
};
