import React from "react";
import { Container, Wrapper, ContainerWrapper, Title } from "../styled";

import {
  PriceContainer,
  PriceRow,
  PriceIcon,
  PriceText,
  ButtonPrice,
  PriceRectangle,
  PriceArea,
  PriceWrapper,
  TitlePrice,
  RegisterWrapper,
} from "./Price.elements";
import { RegisterButton } from "../Hero/RegisterButton";

function Price({
  mainPriceTitle,
  dataJunior,
  dataSenior,
  price,
  formJunior,
  formSenior,
  isDisabled,
  showLogInButton,
  showNewsletter,
  showBorderBottom,
}) {
  return (
    <Container id="price">
      <Wrapper>
        <PriceWrapper showBorderBottom={showBorderBottom}>
          <TitlePrice>{mainPriceTitle}</TitlePrice>

          <ContainerWrapper>
            <PriceRectangle>
              <PriceArea>{price}</PriceArea>
              <PriceContainer>
                {dataJunior?.map((item) => {
                  return (
                    <PriceRow key={item.text} data={dataJunior}>
                      <PriceIcon src="/images/icons/done.svg" />
                      <PriceText>{item.text}</PriceText>
                    </PriceRow>
                  );
                })}

                <RegisterWrapper>
                  <RegisterButton
                    showLogInButton={showLogInButton}
                    formJunior={formJunior}
                    isDisabled={isDisabled}
                  />
                </RegisterWrapper>
              </PriceContainer>
            </PriceRectangle>

            {/* 
                        <PriceRectangle>
                            <Title>Pokročilí</Title>
                            <PriceArea>
                            
                            </PriceArea>
                            <PriceContainer>
                                {dataSenior?.map((item) => {
                                    return (
                                        <PriceRow key={item.text} data={dataSenior}>
                                            <PriceIcon src="/images/icons/done.svg" />
                                            <PriceText>{item.text}</PriceText>
                                        </PriceRow>
                                    )
                                })
                                }

                                <RegisterWrapper>
                                    <RegisterButton showLogInButton={showLogInButton} formSenior={formSenior} />
                                </RegisterWrapper>
                            </PriceContainer>
                        </PriceRectangle>*/}
          </ContainerWrapper>
        </PriceWrapper>
      </Wrapper>
    </Container>
  );
}

export default Price;
