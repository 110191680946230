import React, { useState, useCallback, useEffect } from "react";

import {
  ProgramTable,
  ProgramTableHead,
  ProgramDataHead,
  ProgramTableRow,
  ProgramTableRowH,
  ProgramTableBody,
  ProgramData,
  ProgramDataTopic,
  ProgramWrapper,
  ProgramDataRow,
  ProgramIcon,
} from "./Program.elements";

import { Container, Wrapper, TitleCenter, LinkA } from "../reusable/styled";

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addListener(updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeListener(updateTarget);
  }, [updateTarget, width]);

  return targetReached;
};

export const Timeline = ({ data }) => {
  const isBreakpoint = useMediaQuery(960);
  return (
    <>
      <Container lightBlue></Container>
      <Container>
        <Wrapper>
          <ProgramWrapper>
            <TitleCenter>Harmonogram Mentoringu</TitleCenter>
            <ProgramTable>
              <ProgramTableHead>
                <ProgramTableRowH>
                  <ProgramDataHead>Datum</ProgramDataHead>
                  <ProgramDataHead></ProgramDataHead>
                  <ProgramDataHead>Místo</ProgramDataHead>
                </ProgramTableRowH>
              </ProgramTableHead>
              {!isBreakpoint && (
                <ProgramTableBody>
                  {data.map((item, i) => {
                    return (
                      <ProgramTableRow key={i} data={data} border={item.border}>
                        <ProgramData>{item.date}</ProgramData>
                        <ProgramDataTopic>{item.topic}</ProgramDataTopic>
                        <ProgramData>
                          <LinkA href={item.address} target="blank">
                            {item.place}
                          </LinkA>
                        </ProgramData>
                      </ProgramTableRow>
                    );
                  })}
                </ProgramTableBody>
              )}
              {isBreakpoint && (
                <ProgramTableBody>
                  {data.map((item, i) => {
                    return (
                      <ProgramTableRow key={i} data={data} border={item.border}>
                        <ProgramDataRow>
                          <ProgramData>
                            <ProgramIcon src="/images/icons/calendar.svg" />
                            {item.date}
                          </ProgramData>
                        </ProgramDataRow>
                        <ProgramDataRow>
                          <ProgramDataTopic>{item.topic}</ProgramDataTopic>
                          <ProgramData>
                            <LinkA href={item.address} target="blank">
                              {item.place && (
                                <ProgramIcon src="/images/icons/gps.svg" />
                              )}
                              {item.place}
                            </LinkA>
                          </ProgramData>
                        </ProgramDataRow>
                      </ProgramTableRow>
                    );
                  })}
                </ProgramTableBody>
              )}
            </ProgramTable>
          </ProgramWrapper>
        </Wrapper>
      </Container>
    </>
  );
};
